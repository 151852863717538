import React from 'react';
import { Link } from 'react-router-dom';
import vendingMachine from '../assets/vending-machine.png';

const Hero: React.FC = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">
              Smart Vape Vending Solutions <span>for Modern Businesses</span>
            </h1>
            <p className="hero-description">
              Transforming venues with smart vending technology that ensures compliance and drives revenue through automated vape product sales.
            </p>
            <div className="hero-actions">
              <Link to="/contact" className="button button-primary">
                Learn More
              </Link>
            </div>
          </div>

          <div className="hero-image">
            <img 
              src={vendingMachine} 
              alt="Neon-lit vape vending machine in a modern nightclub setting" 
              className="hero-vending-machine"
            />
            <div className="hero-image-glow"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
