import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FAQItem } from '../types';

const faqs: FAQItem[] = [
  {
    question: "What's included in the partnership",
    answer: "Our partnership includes professional installation, ongoing maintenance, automated restocking, and 24/7 technical support. We handle everything from compliance monitoring to inventory management."
  },
  {
    question: "What maintenance is provided",
    answer: "We provide comprehensive maintenance including regular machine inspections, cleaning, software updates, and immediate response to any technical issues. Our team ensures your vending machines operate at peak performance."
  },
  {
    question: "How often are machines restocked",
    answer: "Machines are restocked based on demand and inventory levels. Our smart monitoring system alerts us when stock is low, ensuring products are always available for your customers."
  },
  {
    question: "What venues are suitable",
    answer: "Our vending solutions are ideal for nightclubs, bars, hotels, and entertainment venues. We assess each location individually to ensure optimal placement and product selection."
  },
  {
    question: "What's the revenue sharing model",
    answer: "We offer flexible revenue sharing models tailored to your venue's specific needs. Our goal is to create a mutually beneficial partnership that maximizes profits for both parties."
  },
  {
    question: "How to report an issue",
    answer: "Issues can be reported 24/7 through our dedicated support line or online portal. Our team responds promptly to ensure minimal disruption to your business."
  }
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="faq-header">
          <p className="faq-label">FREQUENTLY ASKED QUESTIONS</p>
          <h2 className="faq-title">Our FAQs</h2>
        </div>

        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            >
              <button
                type="button"
                className="faq-question"
                onClick={() => toggleFAQ(index)}
                aria-expanded={activeIndex === index}
                aria-controls={`faq-answer-${index}`}
                id={`faq-question-${index}`}
              >
                {faq.question}
                <FaChevronDown className="faq-icon" aria-hidden="true" />
              </button>
              <div 
                id={`faq-answer-${index}`}
                className="faq-answer"
                role="region"
                aria-labelledby={`faq-question-${index}`}
              >
                <p className="faq-answer-text">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
