import React, { useState, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface FormData {
  name: string;
  email: string;
  company: string;
  message: string;
}

const FORM_ENDPOINT = 'https://script.google.com/macros/s/AKfycbweAYNeXNb0gATW2OIgRwNH-d3upFGwdXnTC1XpzD03TdNEGMqghb7M8ODfFORIczPK/exec';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [showSuccessOverlay, setShowSuccessOverlay] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      message: ''
    });
    setSubmitStatus('idle');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        mode: 'no-cors'
      });

      setSubmitStatus('success');
      setShowSuccessOverlay(true);
      resetForm();

    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="contact">
      <div className="container">
        <div className="contact-content">
          <div className="contact-info">
            <p className="contact-label">GET IN TOUCH</p>
            <h1 className="contact-title">
              Reach out to us for any help and inquires
            </h1>
          </div>

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="John white"
                  required
                  className="form-input"
                  disabled={isSubmitting}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="john@nightclub.com"
                  required
                  className="form-input"
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="company">Company name</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Club Poppin"
                required
                className="form-input"
                disabled={isSubmitting}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                required
                className="form-input form-textarea"
                rows={6}
                disabled={isSubmitting}
              />
            </div>
            
            {submitStatus === 'error' && (
              <div className="form-message error">
                There was an error sending your message. Please try again.
              </div>
            )}

            <button 
              type="submit" 
              className={`button button-primary ${isSubmitting ? 'loading' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send message'}
            </button>
          </form>
        </div>
      </div>

      {showSuccessOverlay && (
        <div className="success-overlay">
          <div className="success-content">
            <div className="success-icon">
              <FaCheckCircle />
            </div>
            <h2 className="success-title">Message Sent Successfully!</h2>
            <p className="success-message">
              Thank you for reaching out. We'll get back to you as soon as possible.
            </p>
            <button 
              className="success-button"
              onClick={() => setShowSuccessOverlay(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
