import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../types';

interface MobileNavigationProps {
  navItems: NavItem[];
  onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void;
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ navItems, onItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? 'hidden' : 'auto';
  };

  return (
    <div className="mobile-nav">
      <button 
        className={`mobile-menu-button ${isOpen ? 'is-active' : ''}`}
        onClick={toggleMenu}
        aria-label="Menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div className={`mobile-menu ${isOpen ? 'is-open' : ''}`}>
        <nav className="mobile-menu-nav">
          {navItems.map((item) => (
            <a
              key={item.href}
              href={item.href}
              onClick={(e) => {
                onItemClick(e, item.href);
                toggleMenu();
              }}
            >
              {item.label}
            </a>
          ))}
        </nav>

        <Link 
          to="/contact" 
          className="mobile-menu-contact"
          onClick={toggleMenu}
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default MobileNavigation;
