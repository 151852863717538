import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || "AIzaSyCcqVSI4YfsvFzyaqSoc6Fqr-Dpkyuk7TY",
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || "makron-vapes-website.firebaseapp.com",
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || "makron-vapes-website",
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || "makron-vapes-website.appspot.com",
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || "1563426804",
  appId: import.meta.env.VITE_FIREBASE_APP_ID || "1:1563426804:web:b2aa89daf787e3245b83a1"
};

const app = initializeApp(firebaseConfig);

export default app;
