import React from 'react';
import { FaSearchLocation, FaCogs, FaChartLine } from 'react-icons/fa';
import { FeatureCardProps, FeatureIconType } from '../types';

const icons: Record<FeatureIconType, typeof FaSearchLocation> = {
  FaSearchLocation,
  FaCogs,
  FaChartLine
};

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon }) => {
  const IconComponent = icons[icon];
  
  return (
    <div className="feature-card">
      <div className="feature-icon">
        <IconComponent size="100%" />
      </div>
      <h3 className="feature-title">{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  );
};

const Features: React.FC = () => {
  return (
    <section className="features" id="solutions">
      <div className="container">
        <div className="features-header">
          <p className="features-label">SOLUTIONS</p>
          <h2 className="features-title">
            Intelligent Vending Solutions for Your Venue Needs
          </h2>
          <p className="features-description">
            Transforming venues with cutting-edge vending technology
          </p>
        </div>

        <div className="features-grid">
          <FeatureCard
            icon="FaSearchLocation"
            title="Understanding Your Venue"
            description="We analyze your space and traffic patterns to optimize vending placement and product selection for maximum revenue potential."
          />
          <FeatureCard
            icon="FaCogs"
            title="Developing Tailored Solutions"
            description="Our technology ensures foolproof age verification and maintains strict compliance with all regulations."
          />
          <FeatureCard
            icon="FaChartLine"
            title="Automated Operations"
            description="Smart inventory management with automated monitoring and regular restocking services."
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
