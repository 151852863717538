import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavItem } from '../types';
import MobileNavigation from './MobileNavigation';

const navItems: NavItem[] = [
  { label: 'About', href: '/#about' },
  { label: 'Solutions', href: '/#solutions' },
  { label: 'Partnership', href: '/#partnership' },
  { label: 'FAQ', href: '/#faq' }
];

const Navigation: React.FC = () => {
  const location = useLocation();

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      window.location.href = href;
      return;
    }
    const element = document.querySelector(href.replace('/', ''));
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <nav className="nav">
      <div className="nav-container">
        <Link to="/" className="nav-brand">
          MAKRON VAPES<sup>®</sup>
        </Link>

        <div className="nav-content">
          <div className="nav-links">
            {navItems.map((item) => (
              <a
                key={item.href}
                href={item.href}
                className="nav-link"
                onClick={(e) => handleScroll(e, item.href)}
              >
                {item.label}
              </a>
            ))}
          </div>

          <Link 
            to="/contact" 
            className="nav-contact-btn"
            aria-label="Contact Us"
          >
            Contact Us
          </Link>
        </div>

        <MobileNavigation 
          navItems={navItems}
          onItemClick={handleScroll}
        />
      </div>
    </nav>
  );
};

export default Navigation;
