import React from 'react';
import { FaShieldAlt, FaUserCheck, FaBox, FaPlug, FaShoppingCart, FaCloud } from 'react-icons/fa';
import { WhyUsFeature } from '../types';

const features: WhyUsFeature[] = [
  {
    icon: 'FaShieldAlt',
    title: 'Age Verification',
    description: 'Built-in age verification system ensures compliance with all regulations.'
  },
  {
    icon: 'FaUserCheck',
    title: 'Automated Compliance',
    description: 'Automated reporting and monitoring of all compliance requirements.'
  },
  {
    icon: 'FaBox',
    title: 'Real-time Inventory',
    description: 'Track inventory levels and sales in real-time with automated monitoring.'
  },
  {
    icon: 'FaPlug',
    title: 'Easy Installation',
    description: 'Professional installation and configuration of vending machines.'
  },
  {
    icon: 'FaShoppingCart',
    title: 'Regular Maintenance',
    description: 'Scheduled maintenance to ensure optimal performance.'
  },
  {
    icon: 'FaCloud',
    title: 'Remote Monitoring',
    description: 'Remote inventory management and monitoring capabilities.'
  }
];

const getIcon = (iconName: string) => {
  const iconProps = { size: "100%", className: "whyus-card-icon" };
  
  switch (iconName) {
    case 'FaShieldAlt':
      return <FaShieldAlt {...iconProps} />;
    case 'FaUserCheck':
      return <FaUserCheck {...iconProps} />;
    case 'FaBox':
      return <FaBox {...iconProps} />;
    case 'FaPlug':
      return <FaPlug {...iconProps} />;
    case 'FaShoppingCart':
      return <FaShoppingCart {...iconProps} />;
    case 'FaCloud':
      return <FaCloud {...iconProps} />;
    default:
      return null;
  }
};

const WhyUs: React.FC = () => {
  return (
    <section className="whyus" id="why-us">
      <div className="container">
        <div className="whyus-header">
          <p className="whyus-label">WHY CHOOSE US</p>
          <h2 className="whyus-title">
            Comprehensive vending solutions for your venue
          </h2>
          <p className="whyus-description">
            We provide end-to-end vending solutions with cutting-edge technology and full compliance monitoring.
          </p>
        </div>

        <div className="whyus-grid">
          {features.map((feature, index) => (
            <div key={index} className="whyus-card">
              <div className="whyus-card-icon-wrapper">
                {getIcon(feature.icon)}
              </div>
              <h3 className="whyus-card-title">{feature.title}</h3>
              <p className="whyus-card-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
