import React from 'react';
import { motion } from 'framer-motion';

const Metrics: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="metrics">
      <div className="container">
        <motion.div 
          className="metrics-container"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <div className="metrics-header">
            <p className="metrics-label">IN NUMBER</p>
            <h2 className="metrics-title">
              At Makron Vapes, we are dedicated to creating profitable partnerships through innovative vending solutions.
            </h2>
          </div>

          <div className="metrics-grid">
            <motion.div 
              className="metric-card"
              variants={itemVariants}
            >
              <div className="metric-content">
                <div className="metric-value">100%</div>
                <div className="metric-label">Compliance Rate</div>
              </div>
            </motion.div>

            <motion.div 
              className="metric-card"
              variants={itemVariants}
            >
              <div className="metric-content">
                <div className="metric-value">24/7</div>
                <div className="metric-label">Support & Service</div>
              </div>
            </motion.div>

            <motion.div 
              className="metric-card"
              variants={itemVariants}
            >
              <div className="metric-content">
                <div className="metric-value">$0</div>
                <div className="metric-label">Upfront Investment</div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Metrics;
